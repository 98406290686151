<style>
.nonbackground .ivu-table,
.nonbackground .ivu-table td {
  background-color: #14141400;
  border-bottom: none;
}
</style>
<template>
  <container>
    <div slot="left_box_content">
      <component :is="curLeftComponent"></component>
    </div>

    <div slot="right_box_content">
      <component :is="curRightComponent"></component>
    </div>

    <div slot="bottom_box_content">
      <component :is="curBottomComponent"></component>
    </div>

    <div slot="center_box_content">
      <component :is="curMapComponent"></component>
    </div>
  </container>
</template>

<script>
import Container from '@/components/common/Container_DataV'
import { sysMixins } from '@/assets/mixins/sys'
// 引入store模块
import situationStoreModule from '@/store/modules/situation_web'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    Bottom: () => import('./components/Bottom'),
    Right: () => import('./components/Right'),
    Left: () => import('./components/Left'),
    Center: () => import('./components/Center')
  },
  created () {
    // 注册动态store模块
    if (!this.$store.hasModule('situationWeb')) {
      this.$store.registerModule('situationWeb', situationStoreModule)
    }
  },
  mounted () {
    this.setLeftComponent('Left')
    this.setRightComponent('Right')
    this.setBottomComponent('Bottom')
    this.setMapComponent('Center')

    this.setBottomHeight('300px')
  },
  destroyed () {
    // 卸载installSetting动态store模块
    this.$store.commit('reset_state')
    // this.$store.unregisterModule('situationWeb')
  }
}
</script>
